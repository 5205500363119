import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/attendances', {
    method: 'get',
    params,
  });
}

export async function create(params) {
  return request('/api/attendances', {
    method: 'post',
    data: params,
  });
}

export async function queryTodayLessons(params) {
  return request('/api/attendances/todayLessons', {
    method: 'get',
    params,
  });
}

export async function update(data) {
  return request('/api/attendances', {
    method: 'put',
    data,
  });
}

export async function settlementQuery(params) {
  return request('/api/attendances/settlement', {
    method: 'get',
    params,
  });
}
